import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import SEO from "./../components/SEO"
import Layout from "../Layout"
import IntersectionObserver from "../components/IntersectionObserver"
import ImageTestimonials from "../components/blocks/ImageTestimonials"
const RightMediaLeftContentBlock = loadable(() =>
  import("./../components/blocks/RightMediaLeftContentBlock")
)
const LeftMediaRightContentBlock = loadable(() =>
  import("./../components/blocks/LeftMediaRightContentBlock")
)
const CenterMediaContentBlock = loadable(() =>
  import("./../components/blocks/CenterMediaContentBlock")
)
const TitleSubtitleBlock = loadable(() =>
  import("./../components/blocks/TitleSubtitleBlock")
)
const TitleSubtitleSliderBlock = loadable(() =>
  import("./../components/blocks/TitleSubtitleSliderBlock")
)
const CardGridBGImageBlock = loadable(() =>
  import("./../components/blocks/CardGridBGImageBlock")
)
const ThreeGridFeaturesBlock = loadable(() =>
  import("../components/blocks/ThreeGridFeaturesBlock")
)
const CTABlock = loadable(() => import("./../components/blocks/CTABlock"))
const WorkCardBlock = loadable(() =>
  import("../components/blocks/WorkCardBlock")
)
const FAQ = loadable(() => import("./../components/blocks/FAQ"))
const SpacerBlock = loadable(() => import("./../components/blocks/SpacerBlock"))
const AppStoreBlock = loadable(() =>
  import("./../components/blocks/AppStoreBlock")
)
const LifeAtWebmob = loadable(() =>
  import("./../components/blocks/LifeAtWebmob")
)
const ShortCode = loadable(() => import("../components/blocks/ShortCode"))
const TechStackCardBlock = loadable(() =>
  import("../components/blocks/TechStackCardBlock")
)
const WorkPortfolioSlider = loadable(() =>
  import("../components/blocks/WorkPortfolioSlider")
)
const TimeLine = loadable(() => import("../components/blocks/TimeLine"))
const VideoLinks = loadable(() => import("../components/blocks/VideoLinks"))
const ColorComponent = loadable(() =>
  import("../components/blocks/ColorComponent")
)
const MyTeams = loadable(() => import("../components/blocks/MyTeams"))
const PDFViewer = loadable(() => import("../components/blocks/PDFViewer"))
const MultipleCTA = loadable(() => import("../components/blocks/MultipleCTA"))
const HiringModelBlock = loadable(() =>
  import("../components/blocks/HiringModelBlock")
)
const BlogCardBlock = loadable(() =>
  import("../components/blocks/BlogCardBlock")
)
const VerticalTabs = loadable(() => import("../components/blocks/VerticalTabs"))
const OurWork = loadable(() => import("../components/blocks/OurWork"))
const FullPageSlider = loadable(() =>
  import("../components/blocks/SectionSlider")
)
const AwardsSlider = loadable(() => import("../components/blocks/AwardsSlider"))
const VideoTestimonials = loadable(() =>
  import("../components/blocks/VideoTestimonials")
)
const Logo = loadable(() => import("../components/blocks/Logos"))
const TechTabStackBlock = loadable(() =>
  import("../components/blocks/TechTabStackBlock")
)
const Testimonials = loadable(() => import("../components/blocks/Testimonials"))
const Statistics = loadable(() => import("../components/blocks/Statistics"))
const TechnologyTools = loadable(() =>
  import("../components/blocks/TechnologyTools")
)

// import AOS from "aos"
// import "aos/dist/aos.css"

const PageTemplate = ({ data, location }) => {
  // console.log(`https://webmobtech.com${location.pathname}`)
  return (
    <Layout location={location}>
      <SEO
        title={data?.wpPage?.seo?.title}
        desc={data?.wpPage?.seo?.metaDesc}
        banner={data?.wpPage?.featuredImage?.node?.mediaItemUrl}
        ogurl={data?.wpPage?.seo?.opengraphUrl}
        ogtitle={data?.wpPage?.seo?.opengraphTitle}
        ogdesc={data?.wpPage?.seo?.opengraphDescription}
        ogbanner={data?.wpPage?.seo?.opengraphImage?.mediaItemUrl}
        tweettitle={data?.wpPage?.seo?.twitterTitle}
        tweetdesc={data?.wpPage?.seo?.twitterDescription}
        tweetbanner={data?.wpPage?.seo?.twitterImage?.mediaItemUrl}
        metaRobotsNoindex={data?.wpPage?.seo?.metaRobotsNoindex}
        canonical={`https://webmobtech.com${location.pathname}`}
        schemas={data?.wpPage?.JSONLdBlock?.jsonLd}
      />
      {/* eslint-disable-next-line array-callback-return */}
      {data.wpPage.blocks.map((i, index) => {
        switch (i.name) {
          case "acf/right-media-left-content":
            return (
              <RightMediaLeftContentBlock
                {...i}
                index={index}
                key={Math.random()}
              />
            )
          case "acf/left-media-right-content":
            return (
              <LeftMediaRightContentBlock
                {...i}
                index={index}
                key={Math.random()}
              />
            )
          case "acf/center-media-center-content":
            return (
              <CenterMediaContentBlock
                {...i}
                index={index}
                key={Math.random()}
              />
            )
          case "acf/title-subtitle":
            return (
              <TitleSubtitleBlock {...i} index={index} key={Math.random()} />
            )
          case "acf/title-subtitle-slider":
            return (
              <TitleSubtitleSliderBlock
                {...i}
                index={index}
                key={Math.random()}
              />
            )
          case "acf/card-grid-bg-img":
            return (
              <CardGridBGImageBlock {...i} index={index} key={Math.random()} />
            )
          case "acf/three-grid-features":
            return (
              <ThreeGridFeaturesBlock
                {...i}
                index={index}
                key={Math.random()}
              />
            )
          case "acf/cta-block":
            return <CTABlock {...i} index={index} key={Math.random()} />
          case "acf/work-card":
            return <WorkCardBlock {...i} index={index} key={Math.random()} />
          case "acf/faq":
            return <FAQ {...i} index={index} key={Math.random()} />
          case "acf/spacer":
            return <SpacerBlock {...i} index={index} key={Math.random()} />
          case "acf/appstore":
            return <AppStoreBlock {...i} index={index} key={Math.random()} />
          case "core/shortcode":
            return <ShortCode {...i} index={index} key={Math.random()} />
          case "acf/masonry-photo-grid":
            return <LifeAtWebmob {...i} index={index} key={Math.random()} />
          case "acf/tech-stack-cards":
            return (
              <TechStackCardBlock {...i} index={index} key={Math.random()} />
            )
          case "acf/work-portfolio-slider":
            return (
              <IntersectionObserver>
                <WorkPortfolioSlider {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/timeline-slider":
            return <TimeLine {...i} index={index} key={Math.random()} />
          case "acf/video-links":
            return <VideoLinks {...i} index={index} key={Math.random()} />
          case "acf/color-component":
            return <ColorComponent {...i} index={index} key={Math.random()} />
          case "acf/my-teams":
            return <MyTeams {...i} index={index} key={Math.random()} />
          case "acf/pdf-viewer":
            return <PDFViewer {...i} index={index} key={Math.random()} />
          case "acf/multiple-cta":
            return <MultipleCTA {...i} index={index} key={Math.random()} />
          case "acf/blog-card":
            return <BlogCardBlock {...i} index={index} key={Math.random()} />
          case "acf/hiring-model":
            return <HiringModelBlock {...i} index={index} key={Math.random()} />
          case "acf/vertical-tab":
            return <VerticalTabs {...i} index={index} key={Math.random()} />
          case "acf/setion-slider":
            return <FullPageSlider {...i} index={index} key={Math.random()} />
          case "acf/our-work":
            return (
              <IntersectionObserver>
                <OurWork {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/awards":
            return (
              <IntersectionObserver>
                <AwardsSlider {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/video-testimonials":
            return (
              <VideoTestimonials {...i} index={index} key={Math.random()} />
            )
          case "acf/image-testimonials":
            return (
              <ImageTestimonials {...i} index={index} key={Math.random()} />
            )
          case "acf/all-logo":
            return (
              <IntersectionObserver>
                <Logo {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/tech-tab-stack":
            return (
              <IntersectionObserver>
                <TechTabStackBlock {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/testimonials":
            return (
              <IntersectionObserver>
                <Testimonials {...i} index={index} key={Math.random()} />
              </IntersectionObserver>
            )
          case "acf/common-statistics":
            return <Statistics {...i} index={index} key={Math.random()} />
          case "acf/technology-tools":
            return <TechnologyTools {...i} index={index} key={Math.random()} />
          default:
            console.log(i)
        }
      })}
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      JSONLdBlock {
        jsonLd {
          jsonldSchema
        }
      }
      seo {
        title
        metaDesc
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphUrl
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
        metaRobotsNoindex
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      blocks {
        name
        __typename

        ... on WpCoreShortcodeBlock {
          attributes {
            text
          }
        }

        ... on WpAcfSpacerBlock {
          SpacerBlock {
            spacer {
              desktop
              mobile
            }
          }
        }

        ... on WpAcfAppstoreBlock {
          AppStoreBlock {
            androidPlayStoreLink
            iosAppStoreLink
          }
        }

        ... on WpAcfMasonryPhotoGridBlock {
          MasonryPhotoGrid {
            gridSize
            title
            subtitle
            images {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
        }

        ... on WpAcfWorkPortfolioSliderBlock {
          WorkPortfolioSlider {
            title
            description
            portfolio {
              ... on WpWork {
                title
                projectFeatureTag {
                  nodes {
                    name
                  }
                }
                WorkCPT {
                  tagline
                  textAlign
                  coverImage {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  android {
                    url
                  }
                  ios {
                    url
                  }
                  website {
                    url
                  }
                  caseStudy {
                    url
                  }
                }
              }
            }
          }
        }

        ... on WpAcfTechStackCardsBlock {
          TechStackCardBlock {
            title
            gridSize
            techCard {
              title
              techStack {
                ... on WpTechStack {
                  title
                  excerpt
                }
              }
            }
          }
        }

        ... on WpAcfTimelineSliderBlock {
          TimeLineSlider {
            timeline {
              year
              title
              description
              images {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
        ... on WpAcfHiringModelBlock {
          HiringModelBlock {
            hiringCard {
              cardTitle
              popular
              subtitle
              description
              tagline
            }
            ctaButton {
              target
              title
              url
            }
          }
        }
        ... on WpAcfFaqBlock {
          FAQ {
            title
            type
            questions {
              question
              answer
            }
            firstid
          }
        }
        ... on WpAcfCtaBlock {
          CTABlock {
            action1 {
              title
              url
            }
            action2 {
              title
              url
            }
            image {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
            type
            title
            subtitle
          }
        }

        ... on WpAcfMyTeamsBlock {
          MyTeams {
            title
            member {
              ... on WpTeam {
                MyTeamsBlock {
                  firstname
                  lastname
                  bio
                  photo {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfWorkCardBlock {
          WorkCard {
            projects {
              ... on WpWork {
                title
                projectFeatureTag {
                  nodes {
                    name
                  }
                }
                projectTechnologyTag {
                  nodes {
                    name
                  }
                }
                projectCountryTag {
                  nodes {
                    name
                  }
                }
                projectIndustryTag {
                  nodes {
                    name
                  }
                }
                WorkCPT {
                  tagline
                  textAlign
                  coverImage {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  android {
                    url
                  }
                  ios {
                    url
                  }
                  website {
                    url
                  }
                  caseStudy {
                    url
                  }
                }
              }
            }
          }
        }

        ... on WpAcfCenterMediaCenterContentBlock {
          CenterMediaContentBlock {
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
              content {
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                listpoint {
                  list
                }
              }
              video
              videoPoster
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
          }
        }

        ... on WpAcfRightMediaLeftContentBlock {
          RightMediaLeftContentBlock {
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              video
              videoPoster
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
              content {
                align
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                techImages {
                  iconsUrl {
                    url
                  }
                  techIcons {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                logo {
                  mediaItemUrl
                  srcSet
                  altText
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
        }

        ... on WpAcfCardGridBgImgBlock {
          CardGridBGImageBlock {
            position
            gridSize
            gridCards {
              backgroundImage {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
              description
              onClickLink {
                ... on WpPage {
                  uri
                }
              }
              title
            }
          }
        }
        ... on WpAcfThreeGridFeaturesBlock {
          ThreeGridFeaturesBlock {
            type
            size
            feature {
              fontello
              cta {
                title
                url
              }
              description
              icon {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
              title
              siconlink {
                target
                url
              }
              sicon {
                altText
                mediaItemUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
              bulletpoints {
                subpoints
              }
            }
          }
        }
        ... on WpAcfTitleSubtitleBlock {
          CommonTitleSubtitleBlock {
            type
            title
            subtitle
            ctaButton {
              title
              target
              url
            }
            ctaButton2 {
              title
              target
              url
            }
            backgroundCover {
              mediaItemUrl
              srcSet
              altText
            }
            contactForm
          }
        }

        ... on WpAcfTitleSubtitleSliderBlock {
          TitleSubtitleSliderBlock {
            sliderGroup {
              title
              subtitle
              cta {
                title
                url
              }
              backgroundCover {
                srcSet
                altText
                mediaItemUrl
              }
            }
          }
        }
        ... on WpAcfLeftMediaRightContentBlock {
          LeftMediaRightContentBlock {
            mainTitle
            mainDescription
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
              content {
                align
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                techImages {
                  iconUrl {
                    url
                  }
                  techIcons {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                logo {
                  mediaItemUrl
                  srcSet
                  altText
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
        }

        ... on WpAcfVideoLinksBlock {
          VideoLinksBlock {
            videoLinks {
              title
              subtitle
              link
              poster {
                altText
                caption
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }

        ... on WpAcfColorComponentBlock {
          innerBlocks {
            name
            ... on WpAcfCenterMediaCenterContentBlock {
              CenterMediaContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
              }
            }

            ... on WpAcfRightMediaLeftContentBlock {
              RightMediaLeftContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  video
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    align
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                    techImages {
                      iconsUrl {
                        url
                      }
                      techIcons {
                        mediaItemUrl
                        srcSet
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                      techTitle
                    }
                    logo {
                      mediaItemUrl
                      srcSet
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
                ctaLink2 {
                  target
                  title
                  url
                }
              }
            }
            ... on WpAcfLeftMediaRightContentBlock {
              LeftMediaRightContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    align
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                    techImages {
                      iconUrl {
                        url
                      }
                      techIcons {
                        mediaItemUrl
                        srcSet
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                      techTitle
                    }
                    logo {
                      mediaItemUrl
                      srcSet
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
                ctaLink2 {
                  target
                  title
                  url
                }
              }
            }
          }
        }

        ... on WpAcfPdfViewerBlock {
          PDFViewerBlock {
            title
            subtitle
            pdfLink
          }
        }

        ... on WpAcfMultipleCtaBlock {
          MultipleCTABlock {
            title
            subtitle
            ctaLinks {
              cta {
                url
                title
              }
            }
          }
        }

        ... on WpAcfBlogCardBlock {
          BlogCard {
            blogs {
              ... on WpPost {
                title
                date(formatString: "MMM DD, YYYY")
                uri
                excerpt
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                author {
                  node {
                    avatar {
                      url
                    }
                    name
                    uri
                  }
                }
                categories {
                  nodes {
                    name
                    link
                  }
                }
              }
            }
          }
        }
        ... on WpAcfVerticalTabBlock {
          dynamicContent
          originalContent
          VerticalTab {
            title
            tabs {
              divId
              tabTitle
              subTitle
              caseStudyLink {
                link {
                  target
                  title
                  url
                }
              }
              pageDetailLink {
                target
                title
                url
              }
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
        ... on WpAcfOurWorkBlock {
          OurWork {
            title
            cards {
              title
              subtitle
              tags {
                tag
              }
              image {
                mediaItemUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
                altText
              }
              link {
                target
                title
                url
              }
            }
          }
        }
        ... on WpAcfSetionSliderBlock {
          SectionSliderBlock {
            cards {
              title
              subtitle
              description
              ctaLink {
                target
                title
                url
              }
              ctaLink2 {
                target
                title
                url
              }
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
              pageDetailLink {
                target
                title
                url
              }
            }
            title
            subTitle
          }
        }
        ... on WpAcfAwardsBlock {
          dynamicContent
          originalContent
          Awards {
            fieldGroupName
          }
        }
        ... on WpAcfVideoTestimonialsBlock {
          dynamicContent
          originalContent
          VideoTestimonials {
            select
            testimonials {
              ... on WpVideoTestimonial {
                id
                title
                VideoTestimonialsCPT {
                  subtitle
                  link
                  poster {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfImageTestimonialsBlock {
          dynamicContent
          originalContent
          ImageTestimonials {
            select
            testimonials {
              ... on WpImageTestimonial {
                id
                title
                ImagesTestimonialsCPT {
                  poster {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfAllLogoBlock {
          Logos {
            title
            type
          }
        }
        ... on WpAcfTestimonialsBlock {
          testimonials {
            title
            type
          }
        }
        ... on WpAcfCommonStatisticsBlock {
          originalContent
          statistics {
            subtitle
            title
            type
            statisticsCards {
              ... on WpStatistic {
                id
                statisticsCPT {
                  statisticsValues {
                    number
                    description
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTechTabStackBlock {
          originalContent
          TechTabStacks {
            division {
              divId
              title
              imagesArray {
                ... on WpTechTabStack {
                  id
                  TechStackTabCPT {
                    cta {
                      target
                      title
                      url
                    }
                    image {
                      mediaItemUrl
                      title
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTechnologyToolsBlock {
          TechnologyToolsBlock {
            gridSize
            feature {
              ... on WpTechnologyTool {
                TechnologyToolsCPT {
                  cta {
                    title
                    url
                  }
                  description
                  icon {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  title
                  siconlink {
                    target
                    url
                  }
                  sicon {
                    altText
                    mediaItemUrl
                    srcSet
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
